import { useState } from "react"
import { Button, Heading, IconRemove } from "suomifi-ui-components"
import { useNavigate } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { UserNameForm } from "./UserNameForm"
import { UserIdentityForm } from "./UserIdentityForm"
import {
  useCreateUserAuthorization,
  useCreateUserAuthorizations,
  useCreateUserIdentifier,
  useDeleteAuthorization,
  useDeleteIdentity,
  useServices,
  useUser
} from "../hooks"
import { Auth, AuthorizationRequest, ErrorCode, User } from "../types"
import { UserAuthorizationModal } from "./UserAuthorizationModal"
import useUpdateUser from "../hooks/useUpdateUser"
import { TimedToast } from "../components/TimedToast"
import { ApiError } from "../utils/error"
import { ErrorNotification } from "../components/ErrorNotification"

type Props = {
  initialUser: User
}

const UserPage = ({ initialUser }: Props) => {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const { data: user } = useUser(initialUser.uuid)
  const { data: services } = useServices()

  const navigate = useNavigate()

  const { mutate: updateUser, isError: isUpdateUserError, status: updateUserStatus } = useUpdateUser()
  const { mutate: mutateUserIdentifier, error: createIdError } = useCreateUserIdentifier()
  const { mutate: mutateUserAuthorization } = useCreateUserAuthorization()
  const { mutate: mutateUserAuthorizations } = useCreateUserAuthorizations()
  const { mutate: deleteAuthorization } = useDeleteAuthorization()
  const { mutate: deleteIdentity } = useDeleteIdentity()

  const createMainUserPrivileges = async () => {
    const userAuthorizations: AuthorizationRequest[] = (user && user.userAuthorizations) || []
    const requests: AuthorizationRequest[] = [
      {
        roleName: Auth.USERMGMT,
        serviceUuid: "ANY",
        user: null
      },
      {
        roleName: Auth.SERVICEMGMT,
        serviceUuid: "ANY",
        user: null
      },
      {
        roleName: "RULEMGMT",
        serviceUuid: "ANY",
        user: null
      },
      {
        roleName: Auth.NOTIFICATIONMGMT,
        serviceUuid: "ANY",
        user: null
      }
    ].filter(
      authorization =>
        !userAuthorizations.find(
          userAuthorization =>
            userAuthorization.roleName === authorization.roleName &&
            userAuthorization.serviceUuid === authorization.serviceUuid
        )
    )
    mutateUserAuthorizations({ authorizations: requests, uuid: (user as User).uuid })
  }

  const renderNotifications = () => {
    return (
      <>
        {createIdError && (
          <ErrorNotification id="create-identifier-error-notification">
            <FormattedMessage
              id={
                createIdError instanceof ApiError && createIdError.error.errorCode === ErrorCode.DUPLICATE_USER_IDENTITY
                  ? "admin.error.user.id.olemassa"
                  : "admin.error.identiteettitallennus.epaonnistui"
              }
            />
          </ErrorNotification>
        )}
        {isUpdateUserError && (
          <ErrorNotification id="update-user-error-notification">
            <FormattedMessage id="admin.error.user.update" />
          </ErrorNotification>
        )}

        <TimedToast status={updateUserStatus}>
          <FormattedMessage id="admin.info.tallennettu" />
        </TimedToast>
      </>
    )
  }

  const renderAuthorizationsTable = () => {
    return (
      <table className="table table-striped my-s">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="admin.kayttooikeus" />
            </th>
            <th>
              <FormattedMessage id="admin.field.kayttaja.palvelu" />
            </th>
          </tr>
        </thead>
        <tbody>
          {user &&
            user.userAuthorizations?.map(authorization => {
              const serviceName = services?.find(service => service.uuid === authorization.serviceUuid)?.name
              return (
                <tr key={authorization.roleName + authorization.serviceUuid}>
                  <td>
                    <FormattedMessage id={authorization.roleName} />
                  </td>
                  <td>{authorization.serviceUuid === "ANY" ? <FormattedMessage id="ANY" /> : serviceName}</td>
                  <td className="text-end">
                    <Button
                      data-testid={`user-delete-authorization-${authorization.id}`}
                      variant="secondaryNoBorder"
                      onClick={() => deleteAuthorization(authorization.id)}
                    >
                      <IconRemove />
                    </Button>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  }

  const renderIdentitySection = () => {
    if (user && user.userIdentities && user.userIdentities.length) {
      return (
        <div className="my-s">
          <table className="table table-striped my-s">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="admin.field.kayttaja.tyyppi" />
                </th>
                <th>
                  <FormattedMessage id="admin.field.kayttaja.tunniste" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="mandate.hetu" />
                </td>
                <td>{user.userIdentities[0].userIdentifier}</td>
                <td className="text-end">
                  <Button
                    data-testid="user-delete-identity"
                    variant="secondaryNoBorder"
                    onClick={() => deleteIdentity(user.userIdentities[0].id)}
                  >
                    <IconRemove />
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }

    return (
      user && (
        <UserIdentityForm onSubmit={userIdentifier => mutateUserIdentifier({ userIdentifier, uuid: user.uuid })} />
      )
    )
  }

  const addAuthorization = async (authorization: AuthorizationRequest) => {
    if (user) {
      mutateUserAuthorization({ authorization, uuid: user.uuid })
    }
    setOpenCreateModal(false)
  }

  const renderAuthorizationSection = () => {
    const singleSelectItems = services
      ?.filter(service => !user?.userAuthorizations?.find(auth => auth.serviceUuid === service.uuid))
      .map(service => {
        return {
          uniqueItemId: service.uuid,
          labelText: service.name
        }
      })

    if (!singleSelectItems) {
      return
    }

    return (
      <>
        {renderAuthorizationsTable()}
        <div className="d-flex gap-s my-xxl">
          <Button data-testid="user-open-modal" onClick={() => setOpenCreateModal(true)}>
            <FormattedMessage id="admin.lisaa.saantojenhallintaoikeus" />
          </Button>
          <Button
            data-testid="user-create-main-user-privileges"
            onClick={createMainUserPrivileges}
            disabled={user?.userAuthorizations?.filter(auth => auth.serviceUuid === "ANY").length === 4}
          >
            <FormattedMessage id="admin.lisaa.paakayttajaoikeudet" />
          </Button>
        </div>

        <UserAuthorizationModal
          visible={openCreateModal}
          onCreate={addAuthorization}
          onCancel={() => setOpenCreateModal(false)}
          user={user}
          singleSelectItems={singleSelectItems}
        />
      </>
    )
  }

  return (
    <>
      {renderNotifications()}
      <Heading variant="h2">{user?.name}</Heading>
      {user && <UserNameForm initialName={user.name} onSubmit={name => updateUser({ ...user, name })} />}
      {renderIdentitySection()}
      {renderAuthorizationSection()}
      <Button data-testid="user-page-back" variant="secondaryNoBorder" onClick={() => navigate("/users")}>
        <FormattedMessage id="admin.button.takaisin" />
      </Button>
    </>
  )
}

export { UserPage }
